html,
body,
#root {
  height: 100%;
  margin: 0 !important;
  flex-direction: column;
}

#root {
  display: flex;
}
